.background-image-1 {
  background-image: url("../images/home/Rectangle\ 5825.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 630px; */
}
.background-image-2 {
  background-image: url("../images/home/Rectangle\ 5825.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 630px;
}
.background-image-3 {
  background-image: url("../images/home/Rectangle\ 5864\ \(1\).png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 630px; */
}
.background-image-4 {
  background-image: url("../images/home/Rectangle\ 5865\ 2.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 630px;
}
