.bg-image-blogDetails {
  background-image: url("../images/blogDetails/Rectangle\ 5807\ \(1\).png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 1002px;
}

.bg-image-blogDetails2 {
  background-image: url("../images/blogDetails/Rectangle\ 5807\ \(2\).png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 1002px;
}

.bg-image-blogDetails3 {
  background-image: url("../images/blogDetails/Rectangle\ 5807\ \(3\).png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 1002px;
}

.bg-image-blogDetails4 {
  background-image: url("../images/blogDetails/RN.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 1002px;
}
