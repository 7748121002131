@import "./banner.css";
@import "./process.css";
@import "./financeFirm.css";
@import "./comingSoon.css";
@import "./akrualts.css";
@import "./akruAdmin.css";
@import "./akruIm.css";
@import "./akruServicing.css";
@import "./akruNetwork.css";
@import "./documentAnalyzer.css";
@import "./aboutUs.css";
@import "./blogDetails.css";
@import "./learnMore.css";
@import "./homeSubPage.css";
@import "./akruAltsSub.css";
@import "./akruImSub.css";
@import "./akruServicingSub.css";
@import "./aOnfiSub.css";
@import "./dataAnalyzerSub.css";
body {
  margin: 0px;
  padding: 0px;
}
.container {
  width: 1100px;
  margin: 0px auto;
}
.container-responsive {
  width: 80%;
  margin: 0px auto;
}

@font-face {
  font-family: "Cera Pro";
  src: local("Cera Pro"),
    url("../../fonts/Cera\ Pro\ Light.otf") format("truetype");
  src: local("Cera Pro"),
    url("../../fonts/Cera\ Pro\ Medium.otf") format("truetype");

  font-weight: 300, 400, 500, 600, 700, 800, 900;
}
